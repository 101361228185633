import React from 'react';
import Instance from "api/override/InstanceModel";
import TerminalParams from "models/TerminalParams";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import SettingManager from './SettingManager';
import uuid from 'react-uuid';
import InstanceActions from 'reducers/types/Instance';
import ImageController from 'controllers/ImageController';
import Connector from 'api/override/ConnectorModel';
import ConnectorActions from 'reducers/types/Connector';
import ImageVersionController from 'controllers/ImageVersionController';
import ConnectorContainerActions from 'reducers/types/ConnectorContainer';
import ConnectorContainer from 'api/override/ConnectorContainerModel';
import ConnectorController from 'controllers/ConnectorController';
import Service from 'api/override/ServiceModel';
import ServiceActions from 'reducers/types/Service';
import ServiceContainer from 'api/override/ServiceContainerModel';
import ServiceController from 'controllers/ServiceController';
import ServiceContainerActions from 'reducers/types/ServiceContainer';
import PortController from 'controllers/PortController';
import InstanceController from 'controllers/InstanceController';
import DispatchController from 'controllers/DispatchController';
import ConnectorContainerController from 'controllers/ConnectorContainerController';
import ServiceContainerController from 'controllers/ServiceContainerController';

// For prevention of the view code becoming too huge
export default class BackOfficeTerminalUtil {

    dispatch: Dispatch<AnyAction>;

    constructor(dispatch: Dispatch<AnyAction>) {
        this.dispatch = dispatch;
    }

    create = (params: string) => {
        let parsed: TerminalParams;
        try {
            parsed = new TerminalParams(params);
            switch (parsed.mainPath()) {
                case 'instance':
                    return this.createInstance(parsed);
                case 'connector':
                    return this.createConnector(parsed);
                case 'connector-container':
                    return this.createCC(parsed);
                case 'service':
                    return this.createService(parsed);
                case 'service-container':
                    return this.createSC(parsed);
                default:
                    throw new Error();
            }
        } catch (e) {
            return 'create [instance|connector|connector-container|service|service-container]';
        }
    }

    createInstance = (params: TerminalParams) => {
        if (!params.hasMainParam()) {
            return (
                <span>
                    create instance [name], flags: -domain?, -admin_email?, -admin_password?, -freightlive?<br />
                    Defaults: domain will slugify name, admin_email will take default admin email setup in the settings of the BO, admin_password will take a random UUID, freightlive will default to false indicating this is not a central instance.
                </span>
            );
        }
        const inst = new Instance({
            name: params.mainParam(),
            domain: params.string('domain', params.mainParam().bbStringToSlug()),
            admin_email: params.string('admin_email', SettingManager.default.getInstanceDefaultEmail().value),
            admin_password: params.string('admin_password', uuid()),
            has_vps: params.boolean('freightlive'),
            database_password: uuid()
        });
        this.dispatch(InstanceActions.CreateInstanceCreate(inst));
        return `Starting creation for ${inst.getFullUrl()}`;
    }

    createConnector = async (params: TerminalParams) => {
        if (!params.hasMainParam()) {
            return (
                <span>
                    create connector [image_id], flags: -name?, -url?, -rancher_ns?, -redirect_image?, -redirect_uri?, -redirect_image_version?<br />
                    Defaults: name will take the image name, url and rancher-ns will slugify the image name. redirect_image and redirect_uri are disabled by default, redirect_uri will default to setup when a redirect_image is found, redirect_image_version default to latest
                </span>
            );
        }
        const image = await new ImageController().getImage(parseInt(params.mainParam()));
        const redirectImage = params.hasValue('redirect_image') ? await new ImageController().getImage(params.number('redirect_image')) : null;
        const riv = params.hasValue('redirect_image_version') ? await new ImageVersionController().getImageVersion(params.number('redirect_image_version')) : null;
        if (image != null && image.isForConnectors()) {
            const conn = new Connector({
                image_id: image.id.toString(),
                name: params.string('name', image.name),
                url: params.string('url', image.name.bbStringToSlug()),
                namespace: params.string('namespace', image.name.bbStringToSlug()),
                redirect_image_id: redirectImage?.id,
                redirect_image_version_id: riv?.id
            });
            this.dispatch(ConnectorActions.CreateConnectorCreate(conn));
            return 'Connector succesfully registered';
        } else {
            return 'Image ID not found or not applicable to connectors';
        }
    }

    createCC = async (params: TerminalParams) => {
        if (!params.hasMainParam()) {
            return (
                <span>
                    create connector-container [connector_uuid] [instance_uuid]
                </span>
            )
        }
        const [connector_uuid, instance_uuid] = params.mainParam().split(' ');
        const connector = await new ConnectorController().getConnector(connector_uuid); 
        const cc = new ConnectorContainer({
            connector_id: connector.id,
            instance_uuid: instance_uuid
        });
        this.dispatch(ConnectorContainerActions.CreateConnectorContainerCreate(cc))
        return 'Creating connector container';
    }

    createService = async (params: TerminalParams) => {
        if (!params.hasMainParam()) {
            return (
                <span>
                    create service [image_id], flags: -name?, -url?, -shared?, -port?<br />
                    Defaults: name will take the image name, url will slugify the image name. shared and port are disabled by default. Shared services will always launch with latest image version
                </span>
            )
        }
        const image = await new ImageController().getImage(parseInt(params.mainParam()));
        if (image != null && image.isForServices()) {
            const service = new Service({
                image_id: image.id,
                name: params.string('name', image.name),
                url: params.string('url', image.name.bbStringToSlug()),
                standalone: params.boolean('shared'),
                has_port: params.boolean('port')
            });
            this.dispatch(ServiceActions.CreateServiceCreate(service));
            return 'Service succesfully registered';
        } else {
            return 'Image ID not found or not applicable to services';
        }
    }

    createSC = async (params: TerminalParams) => {
        if (!params.hasMainParam()) {
            return (
                <span>
                    create service-container [service_uuid] [instance_uuid]
                </span>
            )
        }
        const [service_uuid, instance_uuid] = params.mainParam().split(' ');
        const service = await new ServiceController().getService(service_uuid); 
        const sc = new ServiceContainer({
            service_id: service.id,
            instance_uuid: instance_uuid
        });
        this.dispatch(ServiceContainerActions.CreateServiceContainerCreate(sc))
        return 'Creating connector container';
    }

    port = async () => {
        return `${(await (new PortController()).getOpenPort())} is free to use`;
    }

    unlock = async (params: string) => {
        let parsed: TerminalParams;
        try {
            parsed = new TerminalParams(params);
            let controller: DispatchController<any>;
            switch (parsed.mainPath()) {
                case 'instance':
                    controller = new InstanceController();
                    break;
                case 'connector':
                    controller = new ConnectorController();
                    break;
                case 'connector-container':
                    controller = new ConnectorContainerController();
                    break;
                case 'service':
                    controller = new ServiceController();
                    break;
                case 'service-container':
                    controller = new ServiceContainerController();
                    break;
                default:
                    throw new Error('Model not recognized');
            }
            if (await controller.unlockModel(parsed.mainParam())) {
                return 'Succesfully unlocked the uuid';
            } else {
                return 'Failed unlocking the uuid';
            }
        } catch (e) {
            return 'unlock [instance|connector|connector-container|service|service-container] [uuid]';
        }
    }

}