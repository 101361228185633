/* eslint-disable no-unused-vars */
import { withSnackbar } from 'notistack'
import BaseComponent, { BaseState } from './base/BaseComponent'
import { componentConnect, ViewProps } from 'components/base/BasePageComponent'
import NotificationCenter from 'services/NotificationCenter'
import NodeNotification from 'models/NodeNotification'
import ActionTypes from 'config/ActionTypes'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import EnvUtil from 'utils/EnvUtil'

declare global {
  interface Window {
    Pusher: any
    Echo: any
  }
}

if (window) {
  window.Pusher = Pusher
  window.Echo = Echo
}

interface State extends BaseState {
  isConnected: boolean
  failCount: number
  loggedFail: boolean
}

class Notifier extends BaseComponent<ViewProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      isConnected: false,
      failCount: 0,
      loggedFail: false,
    }
  }

  componentDidMount() {
    // listen to window active
    window.addEventListener('focus', this.connectToWebsocket)
    // window.addEventListener('blur', () => {
    // this.setState({
    //   isConnected: false,
    //   failCount: 0,
    // })
    // })
    this.connectToWebsocket()
  }

  connectToWebsocket = () => {
    if (this.state.isConnected) {
      return
    }
    if (!document.hasFocus()) {
      return
    }
    if (this.state.failCount > 10) {
      // display error connecting to ws?
      if (!this.state.loggedFail) {
        this.setState(
          {
            loggedFail: true,
          },
          () => {
            this.log('stopped attempting to connect due to repeated failure')
          }
        )
      }
      return
    }
    let user = JSON.parse(localStorage.getItem('user'))

    this.log(user)

    if (user) {
      // let ws = new WebSocket('wss://backoffice.bumbal.eu:32767/'+user.uuid, localStorage.getItem('jwt'));
      // let ws = new WebSocket('http://localhost:8080', 'http');

      let authEndpoint = EnvUtil.isDevelopment()
        ? 'http://localhost:9050'
        : EnvUtil.isStage()
        ? 'https://stage-backoffice.bumbal.eu'
        : 'https://backoffice.bumbal.eu'

      window.Echo = new Echo({
        broadcaster: 'reverb',
        key: process.env.REACT_APP_REVERB_KEY,
        wsHost: EnvUtil.getUrl(),
        wsPort: 32767,
        wssPort: 443,
        forceTLS: !EnvUtil.isDevelopment(),
        enabledTransports: ['ws', 'wss'],
        authEndpoint: `${authEndpoint}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            Accept: 'application/json',
          },
        },
      })

      window.Echo.connector.pusher.connection.bind('connected', () => {
        if (!this.state.isConnected) this.websocketOnOpen()
      })

      window.Echo.connector.pusher.connection.bind('disconnected', () => {
        this.websocketOnClose()
      })

      window.Echo.connector.pusher.connection.bind('unavailable', () => {
        this.websocketOnError()
      })

      window.Echo.connector.pusher.connection.bind('error', () => {
        this.websocketOnError()
      })

      window.Echo.connector.pusher.connection.bind('failed', () => {
        this.websocketOnError()
      })

      window.Echo.private('WebNotifications.' + user.uuid).listen(
        '.client:send-web-notification',
        (e: NodeNotification) => {
          this.websocketOnMessage(e)
        }
      )

      window.Echo.private('AllNotifications').listen(
        '.client:send-web-notification',
        (e) => {
          this.websocketOnMessage(e)
        }
      )
    }
  }

  websocketOnOpen = () => {
    console.log('connected')
    this.setState({
      isConnected: true,
      failCount: 0,
    })
  }

  websocketOnMessage = (evt) => {
    // post through app with notification center
    try {
      let obj: NodeNotification = evt
      obj.data.timestamp = new Date(obj.data.timestamp)
      this.log('message', obj)

      if (obj.message !== '') {
        const notificationCenterDidSend =
          NotificationCenter.default.sendNotification(
            { type: obj.data.process_name as ActionTypes, params: obj },
            null
          )
        if (!notificationCenterDidSend) {
          this.props.enqueueSnackbar(obj.message, {
            variant: obj.status ?? 'info',
          })
        }
      }
    } catch (err) {
      this.log('error', err)
    }
  }

  websocketOnClose = () => {
    this.log('disconnected')
    this.setState(
      {
        isConnected: false,
      },
      this.connectToWebsocket
    )
  }

  websocketOnError = () => {
    this.setState({
      failCount: this.state.failCount + 1,
    })
  }

  renderView() {
    return null
  }
}

export default componentConnect(
  () => {},
  () => {
    return {}
  },
  withSnackbar(Notifier)
)
