import React from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { TableParams } from 'models/table/TableParams';
import ServiceContainer from 'api/override/ServiceContainerModel';
import ServiceMigrationController from 'controllers/ServiceMigrationController';
import SingleSelect from './SingleSelect';

interface SCVersionSelectProps<T> {
    formik: FormikProps<T>
    disabled?: boolean;
    label?: string;
    serviceContainer: ServiceContainer;
}

function SCVersionSelect<T>(props: SCVersionSelectProps<T>) {
    const currentVersion = props.formik.values['version'] as MultiSelectOption;
    const { t } = useTranslation()
    const [params] = React.useState(TableParams.getIncrementingSelectParams());
    const getVersions = new ServiceMigrationController().serviceMigrationVersionToMultiSelect(props.serviceContainer.uuid);

    const setVersion = (_, val: MultiSelectOption) => {
        props.formik.setFieldValue('version', val);
    }

    return (
        <SingleSelect
            disabled={props.disabled ?? false}
            label={props.label ?? t('service_container.versions.versions')}
            name="version"
            value={currentVersion}
            disablePaging
            getAsyncOptions={getVersions}
            tableParams={params}
            chipProps={{ color: 'primary' }}
            onChange={setVersion}
        />
    )
}

export default SCVersionSelect;