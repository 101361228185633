import { ServiceVersionCreateSuccessModel, ServiceVersionListResultModel, ServiceVersionRemoveSuccessModel, ServiceVersionRetrieveListModel } from "api";
import ServiceVersion from "api/override/ServiceVersionModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface ServiceVersionReducerAction extends BaseReducerAction {
    errorObj?: any;
}
export interface ServiceVersionList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_VERSION_LIST;
    params: {
        uuid: string;
        listParams: ServiceVersionRetrieveListModel;
    }
}

export interface ServiceVersionListSuccess extends BaseSagaListResponse<ServiceVersionListResultModel> {
    type: ActionTypes.API_SERVICE_VERSION_LIST_SUCCESS;
}

export interface ServiceVersionCreate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_VERSION_CREATE;
    params: ServiceVersion;
}

export interface ServiceVersionCreateSuccess extends BaseSagaListResponse<ServiceVersionCreateSuccessModel> {
    type: ActionTypes.API_SERVICE_VERSION_CREATE_SUCCESS;
}

export interface ServiceVersionUpdateParams {
    uuid: string;
    serviceVersionModel: ServiceVersion;
}

export interface ServiceVersionUpdate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_VERSION_UPDATE;
    params: ServiceVersionUpdateParams;
}

export interface ServiceVersionUpdateSuccess extends BaseSagaListResponse<ServiceVersionCreateSuccessModel> {
    type: ActionTypes.API_SERVICE_VERSION_UPDATE_SUCCESS;
}

export interface ServiceVersionDelete extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_VERSION_DELETE;
    params: ServiceVersion;
}

export interface ServiceVersionDeleteSuccess extends BaseSagaListResponse<ServiceVersionRemoveSuccessModel> {
    type: ActionTypes.API_SERVICE_VERSION_DELETE_SUCCESS;
}

export interface ServiceVersionClearListParams {
    force?: boolean;
}
export interface ServiceVersionClearList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_VERSION_LIST_CLEAR;
    params?: ServiceVersionClearListParams;
}

export default class ServiceVersionActions {
    
    static CreateServiceVersionList(serviceUuid: string, params: ServiceVersionRetrieveListModel): ServiceVersionList {
        return {
            type: ActionTypes.API_SERVICE_VERSION_LIST,
            params: {
                uuid: serviceUuid,
                listParams: params
            }
        }
    }

    static CreateServiceVersionListSuccess(params: ServiceVersionListResultModel): ServiceVersionListSuccess {
        return {
            type: ActionTypes.API_SERVICE_VERSION_LIST_SUCCESS,
            params: params
        }
    }

    static CreateServiceVersionCreate(params: ServiceVersion): ServiceVersionCreate {
        return {
            type: ActionTypes.API_SERVICE_VERSION_CREATE,
            params: params
        }
    }

    static CreateServiceVersionCreateSuccess(params: ServiceVersionCreateSuccessModel): ServiceVersionCreateSuccess {
        return {
            type: ActionTypes.API_SERVICE_VERSION_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceVersionUpdate(params: ServiceVersion): ServiceVersionUpdate {
        return {
            type: ActionTypes.API_SERVICE_VERSION_UPDATE,
            params: {
                uuid: params.uuid,
                serviceVersionModel: params
            }
        }
    }

    static CreateServiceVersionUpdateSuccess(params: ServiceVersionCreateSuccessModel): ServiceVersionUpdateSuccess {
        return {
            type: ActionTypes.API_SERVICE_VERSION_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceVersionDelete(serviceVersion: ServiceVersion): ServiceVersionDelete {
        return {
            type: ActionTypes.API_SERVICE_VERSION_DELETE,
            params: serviceVersion
        }
    }

    static CreateServiceVersionDeleteSuccess(params: ServiceVersionRemoveSuccessModel): ServiceVersionDeleteSuccess {
        return {
            type: ActionTypes.API_SERVICE_VERSION_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateClearServiceVersionList(force: boolean = false): ServiceVersionClearList {
        return {
            type: ActionTypes.API_SERVICE_VERSION_LIST_CLEAR,
            params: {force: force}
        }
    }
}