import React from 'react';
import MultiSelect from 'components/form_controls/MultiSelect';
import MultiSelectOption from 'interface/MultiSelectOption';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { TableParams } from 'models/table/TableParams';
import Service from 'api/override/ServiceModel';
import ServiceController from 'controllers/ServiceController';

interface ServiceMultiSelectProps<T> {
    formik: FormikProps<T>
    disabled?: boolean;
    label?: string;
}

function ServiceMultiSelect<T>(props: ServiceMultiSelectProps<T>) {
    const currentServices = props.formik.values['services'] as Service[] ?? [];
    const { t } = useTranslation()
    const [params] = React.useState(TableParams.getIncrementingSelectParams());
    const getServices = new ServiceController().serviceToMultiselect;

    const setServices = (_, val: MultiSelectOption[]) => {
        props.formik.setFieldValue('services', val);
    }

    return (
        <MultiSelect
            disabled={props.disabled ?? false}
            label={props.label ?? t('service.model_plural')}
            name="services"
            value={currentServices}
            disablePaging
            getAsyncOptions={getServices}
            tableParams={params}
            chipProps={{ color: 'primary' }}
            onChange={setServices}
        />
    )
}

export default ServiceMultiSelect;