import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import ActionTypes from 'config/ActionTypes';
import TableList from 'components/TableList';
import { TableParams, TableParamsSorting } from 'models/table/TableParams';
import useTableDelegateHook from 'hooks/TableDelegateHook';
import { useTranslation } from 'react-i18next';
import TableColumn from 'models/table/TableColumn';
import { dateTimeFormat } from 'config/DateTimeFormatter';
import Service from 'api/override/ServiceModel';
import ServiceVersionActions from 'reducers/types/ServiceVersion';
import ServiceVersion from 'api/override/ServiceVersionModel';
import ServiceVersionEditor from './ServiceVersionEditor';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import ButtonStyled from 'components/styled/ButtonStyled';
import { Grid } from '@mui/material';

interface ServiceVersionListProps {
    service: Service;
}

const ServiceVersionList = (props: ServiceVersionListProps) => {
    const [tableParams] = useState<TableParams>(new TableParams( 25, 0, new TableParamsSorting('created_at', 'desc'), '', [] ))
    const versions = useSelector((state: RootState) => state.services.versions);
    const appReducer = useSelector((state: RootState) => state.app);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ServiceVersion | undefined>(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
            dispatch(ServiceVersionActions.CreateServiceVersionList(props.service.uuid, tableParams.getApiFilter()))
        },
        onEdit(item: ServiceVersion) {
            setSelectedItem(item);
            setIsEditModalOpen(true);
        },
        onDelete(item: ServiceVersion) {
            setSelectedItem(item);
            setIsDeleteModalOpen(true);
        }
    });
    const onVersionsChange = () => {
        delegate.getData();
        clearModals();
    }
    const onVersionDelete = () => {
        dispatch(ServiceVersionActions.CreateServiceVersionDelete(selectedItem))
    }
    const clearModals = () => {
        setSelectedItem(null);
        setIsEditModalOpen(false);
        setIsDeleteModalOpen(false);
    }
    const onVersionCreate = () => {
        setSelectedItem(new ServiceVersion({
            uuid: props.service.uuid
        }))
        setIsEditModalOpen(true)
    }
    useEffect(() => {
        const ncDelete = new NCListener(ActionTypes.API_SERVICE_VERSION_DELETE_SUCCESS, onVersionsChange)
        const ncCreate = new NCListener(ActionTypes.API_SERVICE_VERSION_CREATE_SUCCESS, onVersionsChange)
        const ncUpdate = new NCListener(ActionTypes.API_SERVICE_VERSION_UPDATE_SUCCESS, onVersionsChange)
        const listeners = [ncDelete, ncCreate, ncUpdate];
        NotificationCenter.default.addNCListeners(listeners)
        return () => {NotificationCenter.default.removeListeners(listeners)}
    // eslint-disable-next-line
    }, [])
    const columns = [
        new TableColumn({ key: 'version', isSortable: false, label: t('general.version')}),
        new TableColumn({ key: 'created_at', isSortable: true, label: t('general.timestamp.created_at'), customValue: (row: ServiceVersion) => dateTimeFormat(row?.created_at, 'do MMM yyyy')}),
    ];
    return (
        <>
            <TableList
                hasEditAction
                hasDeleteAction
                columns={columns}
                count={versions.count_filtered}
                listItems={versions.items}
                isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_SERVICE_VERSION_LIST)}
                tableParams={ tableParams }
                delegate={ delegate } />
            { isEditModalOpen ? (
                <ServiceVersionEditor
                    serviceVersion={selectedItem}
                    onDidSave={onVersionsChange}
                    onModalClosePress={clearModals}
                />
            ) : (null)}
            { isDeleteModalOpen ? (
                <ConfirmDeleteDialog
                    open
                    title={t('global.title.delete_confirm_model', {model: (t('service_version.model')).toLowerCase()})}
                    onConfirm={onVersionDelete}
                    onModalClosePress={clearModals}
                />
            ) : (null)}
            <Grid container>
                <Grid item xs={12} style={{padding: 16}}>
                    <ButtonStyled style={{width: '100%'}} onClick={onVersionCreate}>{t('global.action.create_model', {model: t('service_version.model')})}</ButtonStyled>
                </Grid>
            </Grid>
        </>
    )
}

export default ServiceVersionList;