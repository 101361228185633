/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceMigrationHistoryVersionModelInterface {
    id?: number;
    version?: string;
    user_email?: string;
    success?: boolean;
    created_at?: Date;
}

/**
 * 
 * @export
 * @interface ServiceMigrationHistoryVersionModel
 */
export class ServiceMigrationHistoryVersionModel extends BaseModel<ServiceMigrationHistoryVersionModelInterface> {

    /**
     * The id of the service version migration
     * @type {number}
     * @memberof ServiceMigrationHistoryVersionModel
     */
    id?: number;
    /**
     * The version of the service
     * @type {string}
     * @memberof ServiceMigrationHistoryVersionModel
     */
    version?: string;
    /**
     * The email of the user who has migrated the service version
     * @type {string}
     * @memberof ServiceMigrationHistoryVersionModel
     */
    user_email?: string;
    /**
     * Whether the migration was successful or not
     * @type {boolean}
     * @memberof ServiceMigrationHistoryVersionModel
     */
    success?: boolean;
    /**
     * The date and time when the service version was migrated
     * @type {Date}
     * @memberof ServiceMigrationHistoryVersionModel
     */
    created_at?: Date;

    constructor(data?: ServiceMigrationHistoryVersionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceMigrationHistoryVersionModel, value: StringDateInterface<ServiceMigrationHistoryVersionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceMigrationHistoryVersionModelFromJSON(json: any): ServiceMigrationHistoryVersionModel {
    return ServiceMigrationHistoryVersionModelFromJSONTyped(json, false);
}

export function ServiceMigrationHistoryVersionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMigrationHistoryVersionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceMigrationHistoryVersionModel;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationHistoryVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'version': !exists(json, 'version') ? undefined : json['version'],
        'user_email': !exists(json, 'user_email') ? undefined : json['user_email'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
    });
}

export function ServiceMigrationHistoryVersionModelToJSON(value?: ServiceMigrationHistoryVersionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceMigrationHistoryVersionModel;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationHistoryVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'version': value.version,
        'user_email': value.user_email,
        'success': value.success,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
    } as StringDateInterface<ServiceMigrationHistoryVersionModel>;
    return createClass.toJson(value, model);
}


