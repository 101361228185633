import { TableParams } from 'models/table/TableParams';
import BaseController from './BaseController'
import MultiSelectOption from 'interface/MultiSelectOption'
import { ServiceMigrationSaga } from 'sagas/ServiceMigrationSagas';
import Log from 'utils/Log';
import ServiceVersion from 'api/override/ServiceVersionModel';

export default class ServiceMigrationController extends BaseController<MultiSelectOption> {

    serviceMigrationVersionToMultiSelect = (serviceUuid: string) => async(params?: TableParams): Promise<MultiSelectOption[]> => {

        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await ServiceMigrationSaga.serviceMigrationVersionList({
                    uuid: serviceUuid,
                    serviceVersionRetrieveListModel: params?.getApiFilter()
                });
                this.cacheResponse(response.items as ServiceVersion[] ?? [], params);
            }
            return this.getItemsForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }

}
