import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid, Chip, Tooltip } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { ViewProps } from 'components/base/BasePageComponent';
import NotificationCenter from 'services/NotificationCenter';
import { ServiceContainerModel, ServiceCreateSuccessModel } from 'api';
import Service from 'api/override/ServiceModel';
import { BaseAction } from 'reducers/interface/ReducerAction';
import NCNotification from 'models/NCNotification';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import TabManager, { TabView } from 'components/tabs/TabManager';
import ServiceState from 'reducers/interface/ServiceState';
import ServiceActions, { ServiceListInstancesParams, ServiceUpdateSecretsParams } from 'reducers/types/Service';
import ServiceCreateForm from 'views/CreationForms/ServiceCreateForm';
import ServiceDetails from './components/ServiceDetails';
import ServiceDetailsDialog from 'views/Dialog/ServiceDetailsDialog';
import TableList from 'components/TableList';
import { tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from 'interface/TableViewDelegate';
import TableColumn from 'models/table/TableColumn';
import Instance from 'api/override/InstanceModel';
import CustomActionButton, { CustomIconButton } from 'models/table/CustomActionButton';
import AppState from 'reducers/interface/AppState';
import ProcessProgress from 'views/Instances/components/ProcessProgress';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import { InstanceTableMenuButton } from 'views/Instances/components/InstanceMenu';
import ServiceContainerActions, { ServiceContainerUpdateParams, ServiceContainerTriggerJobParams } from 'reducers/types/ServiceContainer';
import ServiceContainerState from 'reducers/interface/ServiceContainerState';
import ServiceContainer from 'api/override/ServiceContainerModel';
import TokenUtil from 'utils/TokenUtil';
import ReloadDataButton from 'components/ReloadDataButton';
import { Details } from 'mdi-material-ui';
import ServiceContainerDocumentationModal from './components/ServiceContainerDocumentationModal';
import HistoryTab from 'components/tabs/HistoryTab';
import ProcessLogsTab from 'components/tabs/ProcessLogsTab';
import DispatchTableViewComponent, { DispatchTableViewState } from 'components/DispatchTableViewComponent';
import Tasks from 'config/Tasks';
import DetailsViewHeader from 'components/DetailsViewHeader';
import Pages from 'utils/Pages';
import ServiceSecretsTab from './components/ServiceSecretsTab';
import ServiceSecret from 'api/override/ServiceSecretModel';
import ServiceContainerSecretsTab from './components/ServiceContainerSecretsTab';
import { AppActions } from 'reducers/types/App';
import ServiceMenu from 'views/Instances/components/ServiceMenu';
import ServicePathTable from './components/ServicePathTable';
import ServiceNameLabel from './components/ServiceNameLabel';
import { AlertCircleOutline } from 'mdi-material-ui';
import ServiceVersionList from './components/ServiceVersionList';
import ServiceVersionActions from 'reducers/types/ServiceVersion';
import { TableParams, TableParamsSorting } from 'models/table/TableParams';

interface StateProps {
    serviceReducer: ServiceState;
    serviceContainerReducer: ServiceContainerState;
    appReducer: AppState;
}

interface DispatchProps {
    createService: (params: Service) => void;
    retrieveService: (params: number) => void;
    deleteService: (params: string) => void;
    updateService: (params: Service) => void;
    listServiceContainers: (params: ServiceListInstancesParams) => void;
    updateServiceContainer: (params: ServiceContainerUpdateParams) => void;
    triggerTasks: (params: ServiceContainerTriggerJobParams) => void;
    updateServiceSecrets: (params: ServiceUpdateSecretsParams) => void;
    updateServiceContainerSecrets: (params: ServiceUpdateSecretsParams) => void;
    clearInstances: () => void;
    clearServices: (params: boolean) => void;
    clearService: () => void;
    deleteServiceContainer: (params: string) => void;
    listVersions: (params: string, tableParams: TableParams) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends DispatchTableViewState {
    service?: Service;
    container?: ServiceContainer;
    instance?: Instance;
    notifications: NCNotification[];
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
    runningProcessIds: string[];
    isCCDocumentationModalOpen: boolean;
    isDeleteServiceContainerModalOpen: boolean;
    selectedServiceContainer: ServiceContainer;
}

class ServiceView extends DispatchTableViewComponent<Props, State> implements TableViewDelegate{

    columns: TableColumn[];
    customActionButtons: CustomActionButton[];

    constructor(props) {
        super(props);

        this.state = {
            service: null,
            container: null,
            instance: null,
            notifications: [],
            isDeleteModalOpen: false,
            isEditModalOpen: false,
            runningProcessIds: [],
            isCCDocumentationModalOpen: false,
            isDeleteServiceContainerModalOpen: false,
            selectedServiceContainer: undefined,
            selected: []
        };

        this.setFailuresToListenTo([
            ActionTypes.API_SERVICE_DELETE_FAILURE, 
            ActionTypes.API_SERVICE_CREATE_FAILURE, 
            ActionTypes.API_SERVICE_UPDATE_FAILURE,
            ActionTypes.API_SERVICE_VERSION_LIST_FAILURE,
            ActionTypes.API_SERVICE_VERSION_CREATE_FAILURE,
            ActionTypes.API_SERVICE_VERSION_UPDATE_FAILURE,
            ActionTypes.API_SERVICE_VERSION_DELETE_FAILURE,
        ]);

        this.customActionButtons = [
            new CustomIconButton('documentation', i18next.t('connector_container.view.docs_img_version_tooltip'), 'documentation', (name?: string, row?: ServiceContainer) => {
                this.setState({isCCDocumentationModalOpen: true, container: row})
            }, Details)
        ]
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('connector_container.view.table_for_instance'), customValue: this.renderName }),
            new TableColumn({ key: 'status', isSortable: true, label: i18next.t('general.status')})
        ];
    }

    renderName = (row: ServiceContainer): JSX.Element => {
        return (
            <ServiceNameLabel name={row.instance.name} configCorrect={row.config_correct} />
        )
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_SERVICE_RETRIEVE_FAILURE,
            ActionTypes.API_SERVICE_CREATE_FAILURE,
            ActionTypes.API_SERVICE_DELETE_FAILURE,
            ActionTypes.API_SERVICE_UPDATE_FAILURE,
            ActionTypes.API_SERVICE_CONTAINER_DELETE_FAILURE
        ]);
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_SERVICE_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_DELETE_SUCCESS, 
            this.onServiceDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CONTAINER_DELETE_SUCCESS,
            this.onContainerDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CONTAINER_UPDATE_SUCCESS,
            this.onServiceContainerUpdateSuccess
        );
        NotificationCenter.default.addListeners(
            this, 
            [
                ActionTypes.NODE_SERVICE_CREATE, 
                ActionTypes.NODE_SERVICE_UPDATE, 
                ActionTypes.NODE_SERVICE_DELETE,
                ActionTypes.NODE_SERVICE_CONTAINER_DELETE
            ],
            (action) => {
                if(action.params?.data?.meta?.service === this.props.params.slug) {
                    this.setState({notifications: this.state.notifications.concat(action)})
                    if (!this.state.runningProcessIds.includes(action?.params?.data?.process_token)){
                        this.setState({runningProcessIds: this.state.runningProcessIds.bbPrepend(action?.params?.data?.process_token)})
                    }
                    if(action.params?.status === 'finished') {
                        this.props.clearInstances()
                        this.props.retrieveService(this.props.params.slug)
                    }
                }
            }
        );
    }

    clearData = () => { 
        this.props.clearInstances() 
        this.props.clearService()
    }

    componentWillUnmount() {
        this.clearData()
        super.componentWillUnmount()
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveService(this.props.params.slug);
            if (this.isPathIncluded('instances')) {
                this.getData()
            }
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('logs')) {
            this.sendNotification({type: ActionTypes.SERVICE_VIEW_UPDATE_LOGS}, null);
        }
        else if(this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_SERVICE_HISTORY_UPDATE}, null);
        } else if (this.isPathIncluded('versions')) {
            this.props.listVersions(this.state.service.uuid, new TableParams( 25, 0, new TableParamsSorting('created_at', 'desc'), '', [] ));
        }
        this.loadData();
    }

    onServiceContainerUpdateSuccess = () => {
        this.getData(); 
        this.dialogClosed();
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('connector_container.documentation')}), {variant: 'success'}); 
    }

    onCreateSuccess = (action: BaseAction) => {
        const serviceSuccess: ServiceCreateSuccessModel = action.params
        this.setState({service: serviceSuccess?.model as Service})
        this.props.navigate(`/services/${serviceSuccess?.model?.uuid}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('service.model')}), {variant: 'success'})
        this.props.clearServices(true)
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({service: action.params}, () => {
            if(!this.customActionButtons.some((button: CustomActionButton) => button.ariaLabel === 'instance-menu')) {
                this.customActionButtons = this.customActionButtons.concat([
                    new InstanceTableMenuButton( this.state.service )
                ])
            }
            this.updateTabTitle(this.state.service.name)
        })
    }
 
    onContainerDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('service_container.model')}), {variant: 'success'})
        this.getData()
    }

    onUpdateSuccess = (action: BaseAction) => {
        this.dialogClosed()
        const serviceSuccess = action.params as ServiceCreateSuccessModel;
        this.setState({service: serviceSuccess.model as Service})
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('service.model')}), {variant: 'success'})
        this.props.clearServices(true)
    }

    onServiceDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('service.model')}), {variant: 'success'})
        this.props.clearServices(true)
        this.props.navigate('/'+ Pages.services)
    }

    getData = () => {
        if (this.isPathIncluded('instances') && this.isDetailsView(this.props) && this.getParams() != null) {
            this.props.listServiceContainers( {uuid: this.props.params.slug, tableParams: this.getParams() });
        }
    }

    getItemCount = (): number => {
        return this.props.serviceReducer.instances.items.length
    }

    onDeleteService = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onDelete = (serviceContainer: ServiceContainer) => {
        this.setState({
            container: serviceContainer,
            isDeleteServiceContainerModalOpen: true,
        });
    };

    onEdit = () => {
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
            isCCDocumentationModalOpen: false,
            isDeleteServiceContainerModalOpen: false
        })
    }

    deleteService = () => {
        this.props.deleteService(this.state.service?.uuid);
        this.dialogClosed()
    };

    deleteServiceContainer = () => {
        this.props.deleteServiceContainer(this.state.container?.uuid)
    }

    shouldRenderProgress = () => {
        return (this.state.notifications.length !== 0)
    }

    shouldRenderServiceDetails = () => {
        return this.isDetailsView(this.props) && this.state.service != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    handleMultiRedeploy(selected: string[]): void {
        this.props.triggerTasks({uuid: selected.bbFirst(), task: Tasks.MULTI_REDEPLOY, uuids: selected.bbRemoveFirstItem()})
    }

    renderView() {
        const { classes, serviceReducer, appReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_SERVICE_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderServiceDetails() &&
                                    <div>
                                        <DetailsViewHeader
                                            model={i18next.t('service.model')}
                                            title={this.state.service?.name}
                                        >
                                            {!this.state.service.config_correct ? (
                                                 <Tooltip title={i18next.t('service_container.view.config_not_sync')}>
                                                    <div style={{position: 'relative', width: 16}}><AlertCircleOutline style={{position: 'absolute', top: -20}} fontSize='small' color='warning' /></div>
                                                </Tooltip>
                                            ) : (null)}
                                            {this.state.service.has_port ? (
                                                <Chip label={i18next.t('service.has_port')} className={`${classes.chip} active`} />
                                            ) : (null)}
                                            {this.state.service.standalone ? (
                                                <Chip label={i18next.t('service.standalone')} className={`${classes.chip} active`} />
                                            ) : (null)}
                                            <div style={{marginLeft: 'auto'}}>
                                                <ServiceMenu service={this.state.service}/>
                                            </div>
                                        </DetailsViewHeader>
                                        <TabManager
                                            items={[
                                                {
                                                    title: i18next.t('general.detail'),
                                                    link: `/services/${this.state.service?.uuid}`,
                                                    view: (
                                                        <ServiceDetails 
                                                            service={this.state.service} 
                                                            onServiceDelete={this.onDeleteService}
                                                            onServiceEdit={this.onEdit}
                                                        />
                                                    )
                                                },{
                                                    title: i18next.t('service.secrets'),
                                                    link: `/services/${this.state.service?.uuid}/secrets`,
                                                    initialTab: this.isPathIncluded('/secrets'),
                                                    view: (
                                                        <ServiceSecretsTab
                                                            service={this.state.service}
                                                            onSaveChanges={(ss: ServiceSecret) => {
                                                                this.props.updateServiceSecrets({
                                                                    uuid: this.state.service.uuid,
                                                                    serviceSecretModel: ss
                                                                })
                                                            }}
                                                        />
                                                    )
                                                },{
                                                    title:  i18next.t('process.model_plural'),
                                                    link: `/services/${this.state.service?.uuid}/logs`,
                                                    initialTab: this.isPathIncluded('logs'),
                                                    view: (
                                                        <ProcessLogsTab model={this.state.service as Service} />
                                                    )
                                                },{
                                                    title: i18next.t('service_container.model'),
                                                    link: `/services/${this.state.service?.uuid}/instances`,
                                                    initialTab: this.isPathIncluded('instances'),
                                                    hidden: this.state.service?.standalone,
                                                    view: (
                                                        <Grid item xs={12} >
                                                            <TableList
                                                                hasDeleteAction={TokenUtil.getTokenUtil().isAccountManager()}
                                                                selectableRows={true}
                                                                selected={this.state.selected}
                                                                columns={this.columns}
                                                                count={serviceReducer.instances.count_filtered}
                                                                listItems={serviceReducer.instances.items}
                                                                isRefreshing={serviceReducer.isFetchingInstances}
                                                                delegate={ this }
                                                                tableParams={this.getParams()}
                                                                customActions={this.customActionButtons}
                                                                idColumn={ 'uuid' } 
                                                            /> 
                                                            { this.getRedeployView(i18next.t('global.action.trigger_model_redeploy', {model: i18next.t('service_container.model')})) }
                                                        </Grid>
                                                    )
                                                },{
                                                    title: i18next.t('service_version.model_plural'),
                                                    link: `/services/${this.state.service?.uuid}/versions`,
                                                    initialTab: this.isPathIncluded('versions'),
                                                    view: (
                                                        <Grid item xs={12}>
                                                            <ServiceVersionList
                                                                service={this.state.service}
                                                            />
                                                        </Grid>
                                                    )
                                                },{
                                                    title: i18next.t('service.container_secrets'),
                                                    link: `/services/${this.state.service?.uuid}/container_secrets`,
                                                    initialTab: this.isPathIncluded('/container_secrets'),
                                                    hidden: this.state.service?.standalone,
                                                    view: (
                                                        <ServiceContainerSecretsTab
                                                            service={this.state.service}
                                                            selectedServiceContainer={this.state.selectedServiceContainer}
                                                            onSelectServiceContainer={(sc: ServiceContainer) => this.setState({selectedServiceContainer: sc})}
                                                            onSaveChanges={(containerUuid: string, ss: ServiceSecret) => {
                                                                this.props.updateServiceContainerSecrets({
                                                                    uuid: containerUuid,
                                                                    serviceSecretModel: ss
                                                                })
                                                            }}
                                                        />
                                                    )
                                                },{
                                                    title: i18next.t('service.view.paths'),
                                                    link: `/services/${this.state.service?.uuid}/paths`,
                                                    initialTab: this.isPathIncluded('paths'),
                                                    view: (
                                                        <ServicePathTable service={this.state.service} />
                                                    )
                                                },{
                                                    title: i18next.t('history.model'),
                                                    link: `/services/${this.state.service?.uuid}/history`,
                                                    initialTab: this.isPathIncluded('history'),
                                                    view: (
                                                        <HistoryTab model={this.state.service} />
                                                    )
                                                }
                                            ]}
                                            onTabSwitch={(newTab: TabView) => {
                                                if (newTab.link.includes('instances')){
                                                    if (serviceReducer.instances.items.length === 0 && !serviceReducer.isFetchingInstances) {
                                                        this.props.listServiceContainers( {uuid: this.props.params.slug, tableParams: this.getParams() });
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <ServiceCreateForm
                                            onFormSubmit={(service: Service) => {
                                                this.props.createService(service);
                                            }}
                                        /> 
                                    </CardContent>
                                }
                                {this.shouldRenderProgress() && 
                                    <CardContent>
                                        {this.state.runningProcessIds.map((processId) => {
                                            return (
                                                <ProcessProgress 
                                                    key={processId}     
                                                    notifications={this.state.notifications.filter((notif) => processId === notif.params.data.process_token)}
                                                    onFinish={(process: NCNotification) => {
                                                        this.props.enqueueSnackbar(i18next.t(`node.${process.params.data.process_name}`, {entity: process.params.data.meta?.name}) + ' ' + i18next.t('global.notification.process_finished'), {variant: 'success'})
                                                    }}
                                            />
                                            )
                                        })}
                                    </CardContent>
                                }
                            </Card>

                            <ConfirmDeleteDialog
                                open={this.state.isDeleteModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteService}
                                title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('service.model')).toLowerCase()})}
                            />

                            <ServiceDetailsDialog
                                open={this.state.isEditModalOpen}
                                onModalClosePress={this.dialogClosed}
                                service={this.state.service}
                                onModalSubmit={(service: Service) => {
                                    this.props.updateService(service)
                                }}
                            />

                            <ConfirmDeleteDialog
                                open={this.state.isDeleteServiceContainerModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteServiceContainer}
                                title={i18next.t('global.title.delete_confirm_model', {model: `${this.state.container?.service?.name} ${this.state.container?.getDeleteDialog()} on ${this.state.container?.instance?.name}`})}
                            />

                            <ServiceContainerDocumentationModal
                                open={this.state.isCCDocumentationModalOpen}
                                serviceContainer={this.state.container}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(serviceContainer: ServiceContainerModel) => {
                                    this.props.updateServiceContainer({
                                        uuid: this.state.container?.uuid, 
                                        text: serviceContainer?.text,
                                        image_version_id: serviceContainer?.image_version_id
                                    })
                                }}
                            />

                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    serviceReducer: state.services,
    serviceContainerReducer: state.serviceContainers,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        createService: (params: Service) => dispatch(ServiceActions.CreateServiceCreate(params)),
        retrieveService: (params: string) => dispatch(ServiceActions.CreateServiceRetrieve(params)),
        deleteService: (params: string) => dispatch(ServiceActions.CreateServiceDelete(params)),
        updateService: (params: Service) => dispatch(ServiceActions.CreateServiceUpdate(params)),
        listServiceContainers: (params: ServiceListInstancesParams) => dispatch(ServiceActions.CreateServiceListInstances(params)),
        updateServiceContainer: (params: ServiceContainerUpdateParams) => dispatch(ServiceContainerActions.CreateServiceContainerUpdate(params)),
        triggerTasks: (params: ServiceContainerTriggerJobParams) => dispatch(ServiceContainerActions.CreateServiceContainerTriggerJob(params)),
        deleteServiceContainer: (params: string) => dispatch(ServiceContainerActions.CreateServiceContainerDelete(params)),
        updateServiceSecrets: (params: ServiceUpdateSecretsParams) => dispatch(ServiceActions.CreateServiceUpdateSecrets(params)),
        updateServiceContainerSecrets: (params: ServiceUpdateSecretsParams) => dispatch(ServiceContainerActions.CreateServiceContainerUpdateSecrets(params)),
        clearInstances: () => dispatch(ServiceActions.CreateClearInstances()),
        clearServices: (params: boolean) => dispatch(ServiceActions.CreateClearServiceList(params)),
        clearService: () => {dispatch(AppActions.CreateAction(ActionTypes.CLEAR_SERVICE)); dispatch(AppActions.CreateAction(ActionTypes.CLEAR_SERVICE_CONTAINER))},
        listVersions: (params: string, tableParams: TableParams) => dispatch(ServiceVersionActions.CreateServiceVersionList(params, tableParams.getApiFilter()))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ServiceView, InstanceViewStyles)
            )
        )
    )
);