/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceVersionFilterModelInterface {
    uuid?: string;
    version?: string;
}

/**
 * 
 * @export
 * @interface ServiceVersionFilterModel
 */
export class ServiceVersionFilterModel extends BaseModel<ServiceVersionFilterModelInterface> {

    /**
     * Unique per service version
     * @type {string}
     * @memberof ServiceVersionFilterModel
     */
    uuid?: string;
    /**
     * Version of the service
     * @type {string}
     * @memberof ServiceVersionFilterModel
     */
    version?: string;

    constructor(data?: ServiceVersionFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceVersionFilterModel, value: StringDateInterface<ServiceVersionFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceVersionFilterModelFromJSON(json: any): ServiceVersionFilterModel {
    return ServiceVersionFilterModelFromJSONTyped(json, false);
}

export function ServiceVersionFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceVersionFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceVersionFilterModel;
    try {
        let localClass = require.context('../override', true)('./ServiceVersionFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    });
}

export function ServiceVersionFilterModelToJSON(value?: ServiceVersionFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceVersionFilterModel;
    try {
        let localClass = require.context('../override', true)('./ServiceVersionFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'version': value.version,
    } as StringDateInterface<ServiceVersionFilterModel>;
    return createClass.toJson(value, model);
}


