import {ServiceVersionModel, ServiceVersionModelInterface, StringDateInterface} from "api";
import MultiSelectOption from "interface/MultiSelectOption";
import isValidJson from "utils/isValidJson";

export default class ServiceVersion extends ServiceVersionModel implements MultiSelectOption {

    value: number | string;
    label: string;

    constructor(data?: ServiceVersionModelInterface) {
        super(data);
        this.config = JSON.stringify(
            isValidJson(data?.config) ? JSON.parse(data?.config) : {},
            null,
            4
        )
        this.value = data.id;
        this.label = data.version;
    }

    static toJson(initValue: ServiceVersion, value: StringDateInterface<ServiceVersion>): StringDateInterface<ServiceVersionModelInterface> {
        return { 
            ...value,
            config: isValidJson(initValue['config']) ? JSON.stringify(JSON.parse(initValue['config'])) : '{}'
        };
    }

}