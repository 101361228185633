/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ServiceMigrationCreateModel,
    ServiceMigrationCreateModelFromJSON,
    ServiceMigrationCreateModelToJSON,
    ServiceMigrationCreateSuccessModel,
    ServiceMigrationCreateSuccessModelFromJSON,
    ServiceMigrationCreateSuccessModelToJSON,
    ServiceMigrationHistoryListModel,
    ServiceMigrationHistoryListModelFromJSON,
    ServiceMigrationHistoryListModelToJSON,
    ServiceMigrationHistoryRetrieveListModel,
    ServiceMigrationHistoryRetrieveListModelFromJSON,
    ServiceMigrationHistoryRetrieveListModelToJSON,
    ServiceMigrationListResultModel,
    ServiceMigrationListResultModelFromJSON,
    ServiceMigrationListResultModelToJSON,
    ServiceVersionListResultModel,
    ServiceVersionListResultModelFromJSON,
    ServiceVersionListResultModelToJSON,
    ServiceVersionRetrieveListModel,
    ServiceVersionRetrieveListModelFromJSON,
    ServiceVersionRetrieveListModelToJSON,
} from '../models';

export interface ServiceMigrationCreateRequest {
    uuid: string;
    serviceMigrationCreateModel: ServiceMigrationCreateModel;
}

export interface ServiceMigrationHistoryListRequest {
    uuid: string;
    serviceMigrationHistoryRetrieveListModel: ServiceMigrationHistoryRetrieveListModel;
}

export interface ServiceMigrationVersionsRequest {
    uuid: string;
    serviceVersionRetrieveListModel: ServiceVersionRetrieveListModel;
}

/**
 * 
 */
export class ServiceMigrationApi extends runtime.BaseAPI {

    /**
     * Create a new service migration
     * Create a new service migration
     */
    async serviceMigrationCreateRaw(requestParameters: ServiceMigrationCreateRequest): Promise<runtime.ApiResponse<ServiceMigrationCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceMigrationCreate.');
        }

        if (requestParameters.serviceMigrationCreateModel === null || requestParameters.serviceMigrationCreateModel === undefined) {
            throw new runtime.RequiredError('serviceMigrationCreateModel','Required parameter requestParameters.serviceMigrationCreateModel was null or undefined when calling serviceMigrationCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-migration/{uuid}/migrate`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceMigrationCreateModelToJSON(requestParameters.serviceMigrationCreateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceMigrationCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new service migration
     * Create a new service migration
     */
    serviceMigrationCreate = async(requestParameters: ServiceMigrationCreateRequest): Promise<ServiceMigrationCreateSuccessModel> => {
        const response = await this.serviceMigrationCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a service migration
     * retrieve a list of historical logs for a service migration
     */
    async serviceMigrationHistoryListRaw(requestParameters: ServiceMigrationHistoryListRequest): Promise<runtime.ApiResponse<ServiceMigrationHistoryListModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceMigrationHistoryList.');
        }

        if (requestParameters.serviceMigrationHistoryRetrieveListModel === null || requestParameters.serviceMigrationHistoryRetrieveListModel === undefined) {
            throw new runtime.RequiredError('serviceMigrationHistoryRetrieveListModel','Required parameter requestParameters.serviceMigrationHistoryRetrieveListModel was null or undefined when calling serviceMigrationHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-migration/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceMigrationHistoryRetrieveListModelToJSON(requestParameters.serviceMigrationHistoryRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceMigrationHistoryListModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a service migration
     * retrieve a list of historical logs for a service migration
     */
    serviceMigrationHistoryList = async(requestParameters: ServiceMigrationHistoryListRequest): Promise<ServiceMigrationHistoryListModel> => {
        const response = await this.serviceMigrationHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all service migrations
     * List all service migrations
     */
    async serviceMigrationListRaw(): Promise<runtime.ApiResponse<ServiceMigrationListResultModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-migration/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceMigrationListResultModelFromJSON(jsonValue));
    }

    /**
     * List all service migrations
     * List all service migrations
     */
    serviceMigrationList = async(): Promise<ServiceMigrationListResultModel> => {
        const response = await this.serviceMigrationListRaw();
        return await response.value();
    }

    /**
     * List all service versions
     * List all service versions
     */
    async serviceMigrationVersionsRaw(requestParameters: ServiceMigrationVersionsRequest): Promise<runtime.ApiResponse<ServiceVersionListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceMigrationVersions.');
        }

        if (requestParameters.serviceVersionRetrieveListModel === null || requestParameters.serviceVersionRetrieveListModel === undefined) {
            throw new runtime.RequiredError('serviceVersionRetrieveListModel','Required parameter requestParameters.serviceVersionRetrieveListModel was null or undefined when calling serviceMigrationVersions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-migration/{uuid}/versions`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceVersionRetrieveListModelToJSON(requestParameters.serviceVersionRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceVersionListResultModelFromJSON(jsonValue));
    }

    /**
     * List all service versions
     * List all service versions
     */
    serviceMigrationVersions = async(requestParameters: ServiceMigrationVersionsRequest): Promise<ServiceVersionListResultModel> => {
        const response = await this.serviceMigrationVersionsRaw(requestParameters);
        return await response.value();
    }

}
