/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceMigrationListResultModelMetaInterface {
    total_count?: number;
    current_page?: number;
    from?: number;
    path?: string;
    per_page?: number;
    to?: number;
}

/**
 * 
 * @export
 * @interface ServiceMigrationListResultModelMeta
 */
export class ServiceMigrationListResultModelMeta extends BaseModel<ServiceMigrationListResultModelMetaInterface> {

    /**
     * 
     * @type {number}
     * @memberof ServiceMigrationListResultModelMeta
     */
    total_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceMigrationListResultModelMeta
     */
    current_page?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceMigrationListResultModelMeta
     */
    from?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceMigrationListResultModelMeta
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceMigrationListResultModelMeta
     */
    per_page?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceMigrationListResultModelMeta
     */
    to?: number;

    constructor(data?: ServiceMigrationListResultModelMetaInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceMigrationListResultModelMeta, value: StringDateInterface<ServiceMigrationListResultModelMetaInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceMigrationListResultModelMetaFromJSON(json: any): ServiceMigrationListResultModelMeta {
    return ServiceMigrationListResultModelMetaFromJSONTyped(json, false);
}

export function ServiceMigrationListResultModelMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMigrationListResultModelMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceMigrationListResultModelMeta;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModelMeta');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'total_count': !exists(json, 'total_count') ? undefined : Number(json['total_count']),
        'current_page': !exists(json, 'current_page') ? undefined : Number(json['current_page']),
        'from': !exists(json, 'from') ? undefined : Number(json['from']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'per_page': !exists(json, 'per_page') ? undefined : Number(json['per_page']),
        'to': !exists(json, 'to') ? undefined : Number(json['to']),
    });
}

export function ServiceMigrationListResultModelMetaToJSON(value?: ServiceMigrationListResultModelMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceMigrationListResultModelMeta;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModelMeta');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'total_count': value.total_count,
        'current_page': value.current_page,
        'from': value.from,
        'path': value.path,
        'per_page': value.per_page,
        'to': value.to,
    } as StringDateInterface<ServiceMigrationListResultModelMeta>;
    return createClass.toJson(value, model);
}


