import { Tooltip, TabsProps, AppBar, Tabs, Tab } from "@mui/material";
import React, { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export interface BaseTabView {
    title: string;
    initialTab?: boolean;
    link?: string;
    disabled?: boolean;
    hidden?: boolean;
    fn?: string;
    tooltip?: string;
}

export interface BaseTabManagerProps {
    tabsProps?: TabsProps;
    items: BaseTabView[];
    onShouldTabSwitch?: (toTab: BaseTabView, fromTab: BaseTabView) => boolean;
    onTabSwitch?: (toTab: BaseTabView, fromTab?: BaseTabView) => void;
    style?: React.CSSProperties;
    renderContent?: (activeIndex: number, items: BaseTabView[]) => JSX.Element;
    appbarElevation?: number;
    appbarBackgroundColor?: string;
}

const BaseTabManager = (props: BaseTabManagerProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState( Math.max(props.items.filter(tab => tab.hidden !== true).findIndex(item => item.initialTab === true && item.disabled !== true && item.hidden !== true), 0) );
    const tabItems = props.items.filter(tab => tab.hidden !== true);
    const updateUrlOnDisabledTab = () => {
        if(location.pathname !== tabItems[activeTab]?.link && tabItems[activeTab]?.link != null) { // change path in history in case the user inputs a URL of a disabled tab manually
            navigate(tabItems[activeTab]!.link!)
        }
        props.onTabSwitch?.(tabItems[activeTab], undefined)
    }
    // eslint-disable-next-line
    React.useEffect(updateUrlOnDisabledTab, [])
    React.useEffect(() => {
        if (props.tabsProps?.value >= 0) {
            setActiveTab(props.tabsProps?.value ?? 0);
        }
    }, [props.tabsProps])
    const handleTabSwitch = (newIndex: number) => {
        const currentTab = tabItems[activeTab];
        const newTab = tabItems[newIndex];
        const shouldSwitch = props.onShouldTabSwitch != null ? props.onShouldTabSwitch?.(newTab, currentTab) : true;
        if (shouldSwitch) {
            setActiveTab(newIndex);
            props.onTabSwitch?.(newTab, currentTab);
        }
    }
    const onChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        handleTabSwitch(newValue);
    };
    const onUrlChange = () => {
        const newIndex = tabItems.findIndex(tab => tab.link === location.pathname.bbReplaceAll(' ', '%20'));
        if (newIndex >= 0) {
            handleTabSwitch(newIndex)
        }
    }
    // eslint-disable-next-line
    React.useEffect(onUrlChange, [location])
    const a11yTabProps = (index: any) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }
    const createTabTitle = (tabItem: BaseTabView): ReactNode => {
        return tabItem.tooltip != null ? (
            <Tooltip title={tabItem.tooltip}>
                <span>{tabItem.title}</span>
            </Tooltip>
        ) : (tabItem.title)
    }

    return (
        <>
            <AppBar variant="elevation" position="relative" color="transparent" elevation={props.appbarElevation ?? 0}>
                <Tabs
                    value={activeTab}
                    onChange={onChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                    {...props.tabsProps}
                >
                    {tabItems.map((tabItem: BaseTabView, index: number) => {
                        if (tabItem.link != null) {
                            return (
                                <Tab key={`tab-${tabItem.title}`} label={createTabTitle(tabItem)} {...a11yTabProps(index)} component={Link} to={tabItem.link} disabled={tabItem?.disabled} />
                            );
                        } else {
                            return (
                                <Tab key={`tab-${tabItem.title}`} label={createTabTitle(tabItem)} {...a11yTabProps(index)} disabled={tabItem?.disabled} />
                            );
                        }
                    })}
                </Tabs>
            </AppBar>
            {props.renderContent?.(activeTab, tabItems)}
        </>
    )
}

export const RawBaseTabManager = BaseTabManager;

export default React.memo(BaseTabManager, (prevProps: BaseTabManagerProps, nextProps: BaseTabManagerProps) => {
    return nextProps.items.map(tv => tv.link).join('') === prevProps.items.map(tv => tv.link).join('')
});
