import ActionTypes from "config/ActionTypes";
import ServiceMigrationState from "./interface/ServiceMigrationState";
import BaseReducerAction from "./interface/ReducerAction";
import ServiceMigrationHistory from "api/override/ServiceMigrationHistoryModel";
import { ServiceMigrationHistorySuccess } from "./types/ServiceMigration";

const initialState: ServiceMigrationState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined
};

export function ServiceMigrationReducers(state = initialState, action: BaseReducerAction): ServiceMigrationState {
    switch (action.type) {
        case ActionTypes.API_SERVICE_MIGRATION_HISTORY:
            return {
                ...state,
                selectedObject: undefined
            };

        case ActionTypes.API_SERVICE_MIGRATION_HISTORY_SUCCESS:
            const result = action as ServiceMigrationHistorySuccess;
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<ServiceMigrationHistory>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                }
            };
        case ActionTypes.CLEAR_SERVICE_MIGRATION_HISTORY:
            return {
                ...state,
                collection: {
                    items: [],
                    count_filtered: 0,
                    count_unfiltered: 0
                }
            }
        default:
            return state;
    }
}