export default class EnvUtil {

    private static shouldSimulateProduction: boolean = false;

    static isStage() {
        return process.env.REACT_APP_DEPLOY_ENV.trim() === 'stage';
    }

    static isProduction() {
        if(EnvUtil.shouldSimulateProduction) {
            return true
        }
        return process.env.REACT_APP_DEPLOY_ENV.trim() === 'production';
    }

    static isDevelopment() {
        return process.env.REACT_APP_DEPLOY_ENV.trim() === 'development';
    }

    static isBackOffice() {
        return process.env.REACT_APP_BACKOFFICE === 'true';
    }

    static getTranslationsVersion(): string {
        return process.env.REACT_APP_BACKOFFICE_TRANSLATION_VERSION;
    }

    static getShouldSimulateProduction(): boolean {
        return EnvUtil.shouldSimulateProduction
    }

    static setShouldSimulateProduction(value: boolean): void {
        EnvUtil.shouldSimulateProduction = value;
    }

    static getUrl(): string {
        return EnvUtil.isDevelopment()
            ? 'localhost'
            : EnvUtil.isStage()
                ? 'stage-backoffice.bumbal.eu'
                : 'backoffice.bumbal.eu'
    }

}