import React, { useState } from 'react';
import ServiceContainer from "api/override/ServiceContainerModel";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import ActionTypes from 'config/ActionTypes';
import TableList from 'components/TableList';
import { TableParams, TableParamsSorting } from 'models/table/TableParams';
import useTableDelegateHook from 'hooks/TableDelegateHook';
import ServiceMigrationActions from 'reducers/types/ServiceMigration';
import { useTranslation } from 'react-i18next';
import TableColumn from 'models/table/TableColumn';
import ServiceMigrationHistoryVersion from 'api/override/ServiceMigrationHistoryModel';
import { dateTimeFormat } from 'config/DateTimeFormatter';
import { CheckCircle, MinusCircle } from 'mdi-material-ui';
import { useTheme } from '@mui/material';

interface ServiceContainerHistoryViewProps {
    serviceContainer: ServiceContainer;
}

const ServiceContainerHistoryView = (props: ServiceContainerHistoryViewProps) => {
    const [tableParams] = useState<TableParams>(new TableParams( 25, 0, new TableParamsSorting('created_at', 'desc'), '', [] ))
    const migrationHistory = useSelector((state: RootState) => state.service_migrations);
    const appReducer = useSelector((state: RootState) => state.app);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
            dispatch(ServiceMigrationActions.CreateServiceMigrationHistory({
                uuid: props.serviceContainer.uuid,
                serviceMigrationHistoryRetrieveListModel: tableParams.getApiFilter()
            }))
        }
    });
    const theme = useTheme();
    const columns = [
        new TableColumn({ key: 'version', isSortable: false, label: t('service_container.versions.versions')}),
        new TableColumn({ key: 'user_email', isSortable: true, label: t('general.timestamp.requested_at')}),
        new TableColumn({ key: 'success', label: t('general.success'), customValue: (row: ServiceMigrationHistoryVersion) => row?.success ? (<CheckCircle htmlColor={theme.palette.success.main} />) : (<MinusCircle htmlColor={theme.palette.error.main} />)}),
        new TableColumn({ key: 'created_at', isSortable: true, label: t('general.timestamp.created_at'), customValue: (row: ServiceMigrationHistoryVersion) => dateTimeFormat(row?.created_at, 'do MMM yyyy')}),
    ];
    return (
        <TableList
            columns={columns}
            count={migrationHistory.collection?.count_filtered}
            listItems={migrationHistory.collection?.items}
            isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_SERVICE_MIGRATION_HISTORY)}
            tableParams={ tableParams }
            delegate={ delegate } />
    )
}

export default ServiceContainerHistoryView;