import ServiceVersion from 'api/override/ServiceVersionModel';
import BaseFormModal from 'components/base/BaseFormModal';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ActionTypes from 'config/ActionTypes';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ServiceVersionActions from 'reducers/types/ServiceVersion';
import { retrieveServiceVersionSchema } from 'schemas/ServiceVersion';
import useDialogStyles from 'styles/DialogStyles';

interface ServiceVersionEditorProps {
    serviceVersion?: ServiceVersion;
    onModalClosePress: () => void;
    onDidSave: () => void;
}

const ServiceVersionEditor = (props: ServiceVersionEditorProps) => {
    const {t} = useTranslation();
    const isEditMode = props.serviceVersion.id != null;
    const dispatch = useDispatch();
    const {classes} = useDialogStyles();

    const onSubmit = (serviceVersion: ServiceVersion) => {
        dispatch(isEditMode
            ? ServiceVersionActions.CreateServiceVersionUpdate(serviceVersion)
            : ServiceVersionActions.CreateServiceVersionCreate(serviceVersion)
        )
    }
    return (
        <BaseFormModal {...props}
            open 
            title={isEditMode ? t('global.title.details_model', {model: t('clients.model')}) : t('global.action.create_model', {model: t('service_version.model')})}
            initialValues={props.serviceVersion}
            action={isEditMode
                ? ActionTypes.API_SERVICE_VERSION_CREATE 
                : ActionTypes.API_SERVICE_VERSION_UPDATE
            }
            validationSchema={retrieveServiceVersionSchema()}
            onSubmit={onSubmit}
            renderForm={(formik: FormikProps<ServiceVersion>) => (
                <div className={classes.fields_container}>
                    <TextFieldStyled  
                        id="version" 
                        name="version"
                        label={t('general.version')}
                        helperText={formik.touched.version ? formik.errors.version : ""}
                        error={formik.touched.version && Boolean(formik.errors.version)}
                        value={formik.values.version}
                        onChange={formik.handleChange}
                    />
                    
                    <TextFieldStyled  
                        id="config" 
                        name="config"
                        label={t('general.config')}
                        helperText={formik.touched.config ? formik.errors.config : ""}
                        error={formik.touched.config && Boolean(formik.errors.config)}
                        value={formik.values.config}
                        onChange={formik.handleChange}
                        rows={0}
                        minRows={8}
                        multiline
                    />
                </div>
            )}
        />
    )
}

export default ServiceVersionEditor;