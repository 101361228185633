import React, { Suspense, useEffect } from 'react';
import { Provider } from "react-redux";
import { I18nextProvider } from 'react-i18next';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { BaseAPI } from 'api';
import { TerminalContextProvider } from "react-terminal";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import 'extensions/index'
import App from './App';
import i18n from './services/i18n';
import AppStore, { history } from './store/AppStore';
import './index.css';
import TokenUtil from 'utils/TokenUtil';
import EnvUtil from 'utils/EnvUtil';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

function Application() {
    if (EnvUtil.isDevelopment()) {
        BaseAPI.setConfig("http://localhost:9050/api/v1", "");
    } else if (EnvUtil.isStage()) {
        BaseAPI.setConfig('https://stage-backoffice.bumbal.eu/api/v1', '');
    } else {
        BaseAPI.setConfig("https://backoffice.bumbal.eu/api/v1", "");
    }

    // if there is a token; set it
    if (!TokenUtil.getTokenUtil().isTokenExpired()) {
        BaseAPI.setConfig(
            BaseAPI.config.basePath,
            'bearer '+localStorage.getItem("jwt")
        )
    }

    useEffect(() => {
        Sentry.init({
            dsn: "https://a454bfc288804632b0bdf95204fc612a@o1337660.ingest.sentry.io/6607675",
            integrations: [new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            })],
            enabled: EnvUtil.isProduction(),
            tracesSampleRate: 0.2,
        });
    }, []);

    // useEffect(() => {
        // const echo = new Echo({
        //     broadcaster: 'reverb',
        //     key: "nmsuhp8kbqhsfkovzfay",
        //     wsHost: "localhost",
        //     wsPort: "8080",
        //     wssPort: "8080",
        //     forceTLS: false,
        //     enabledTransports: ['ws', 'wss'],
        // });

        // echo.channel('WebNotifications').listen('TestEvent', (e) => {
        //     alert('AAA');

        //     console.log(e);
        // });
    // }, []);

    return (
        <I18nextProvider i18n={i18n} >
            <TerminalContextProvider>
                <Provider store={AppStore} >
                    <Router history={history} >
                        <Suspense fallback={!EnvUtil.isDevelopment() ? <div></div> : <div style={{backgroundImage: "url(https://c.tenor.com/Y496Q1n5MosAAAAC/spooky-scary.gif)", backgroundRepeat: "repeat", position: 'absolute', width: '100vw', height: '100vh'}}></div>}>
                            <App />
                        </Suspense>
                    </Router>
                </Provider>
            </TerminalContextProvider>
        </I18nextProvider>
    )
}

const root = createRoot(document.getElementById('root'));
root.render( < Application /> )