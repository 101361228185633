import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ServiceApi, ServiceVersionCreateRequest, ServiceVersionDeleteRequest, ServiceVersionListRequest, ServiceVersionListResultModel, ServiceVersionUpdateRequest } from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import ServiceVersionActions, { ServiceVersionCreate, ServiceVersionDelete, ServiceVersionList, ServiceVersionUpdate } from 'reducers/types/ServiceVersion';

export function* ServiceVersionSagas() {
    yield takeLatest(ActionTypes.API_SERVICE_VERSION_LIST, ServiceVersionSaga.serviceVersionListWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_VERSION_CREATE, ServiceVersionSaga.serviceVersionCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_VERSION_UPDATE, ServiceVersionSaga.serviceVersionUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_VERSION_DELETE, ServiceVersionSaga.serviceVersionDeleteWorkerSaga);
}

export class ServiceVersionSaga {
    static *serviceVersionListWorkerSaga(action: ServiceVersionList) {
        try {
            const serviceVersionArgs = {
                uuid: action.params.uuid,
                serviceVersionRetrieveListModel: action.params.listParams
            } as ServiceVersionListRequest
            const data = yield ApiHandler(ServiceVersionSaga.serviceVersionList, serviceVersionArgs);
    
            yield put(ServiceVersionActions.CreateServiceVersionListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_VERSION_LIST_FAILURE, error as Error))
        }
    }

    static *serviceVersionCreateWorkerSaga(action: ServiceVersionCreate) {
        try {
            const serviceVersionArgs = {
                uuid: action.params.uuid,
                serviceVersionModel: action.params
            } as ServiceVersionCreateRequest
            const data = yield ApiHandler(new ServiceApi().serviceVersionCreate, serviceVersionArgs);
    
            yield put(ServiceVersionActions.CreateServiceVersionCreateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_VERSION_CREATE_FAILURE, error as Error))
        }
    }
    
    static *serviceVersionUpdateWorkerSaga(action: ServiceVersionUpdate) {
        try {
            const serviceVersionArgs = {
                uuid: action.params?.uuid,
                id: action.params?.serviceVersionModel.id,
                serviceVersionModel: action.params?.serviceVersionModel
            } as ServiceVersionUpdateRequest
            const data = yield ApiHandler(new ServiceApi().serviceVersionUpdate, serviceVersionArgs);
    
            yield put(ServiceVersionActions.CreateServiceVersionUpdateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_VERSION_UPDATE_FAILURE, error as Error))
        }
    }
    
    static *serviceVersionDeleteWorkerSaga(action: ServiceVersionDelete) {
        try {
            const serviceVersionArgs = {
                uuid: action.params.uuid,
                id: action.params.id
            } as ServiceVersionDeleteRequest
            const data = yield ApiHandler(new ServiceApi().serviceVersionDelete, serviceVersionArgs);
    
            yield put(ServiceVersionActions.CreateServiceVersionDeleteSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_VERSION_DELETE_FAILURE, error as Error))
        }
    }
    
    static async serviceVersionList (params: ServiceVersionListRequest): Promise<ServiceVersionListResultModel> {
        return await new ServiceApi().serviceVersionList(params);
    }
}

