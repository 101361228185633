/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceVersionModelInterface {
    id?: number;
    uuid?: string;
    version?: string;
    config?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

/**
 * 
 * @export
 * @interface ServiceVersionModel
 */
export class ServiceVersionModel extends BaseModel<ServiceVersionModelInterface> {

    /**
     * id of the service version
     * @type {number}
     * @memberof ServiceVersionModel
     */
    id?: number;
    /**
     * uuid of the service
     * @type {string}
     * @memberof ServiceVersionModel
     */
    uuid?: string;
    /**
     * The version of the service
     * @type {string}
     * @memberof ServiceVersionModel
     */
    version?: string;
    /**
     * The required configuration for a service version migration
     * @type {string}
     * @memberof ServiceVersionModel
     */
    config?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceVersionModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceVersionModel
     */
    updated_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceVersionModel
     */
    deleted_at?: Date;

    constructor(data?: ServiceVersionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceVersionModel, value: StringDateInterface<ServiceVersionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceVersionModelFromJSON(json: any): ServiceVersionModel {
    return ServiceVersionModelFromJSONTyped(json, false);
}

export function ServiceVersionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceVersionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceVersionModel;
    try {
        let localClass = require.context('../override', true)('./ServiceVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'config': !exists(json, 'config') ? undefined : json['config'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function ServiceVersionModelToJSON(value?: ServiceVersionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceVersionModel;
    try {
        let localClass = require.context('../override', true)('./ServiceVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'uuid': value.uuid,
        'version': value.version,
        'config': value.config,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toApiFormat()),
    } as StringDateInterface<ServiceVersionModel>;
    return createClass.toJson(value, model);
}


