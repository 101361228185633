/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceMigrationListResultModelAttributesInterface {
    name?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date | null;
}

/**
 * 
 * @export
 * @interface ServiceMigrationListResultModelAttributes
 */
export class ServiceMigrationListResultModelAttributes extends BaseModel<ServiceMigrationListResultModelAttributesInterface> {

    /**
     * 
     * @type {string}
     * @memberof ServiceMigrationListResultModelAttributes
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceMigrationListResultModelAttributes
     */
    created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServiceMigrationListResultModelAttributes
     */
    updated_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServiceMigrationListResultModelAttributes
     */
    deleted_at?: Date | null;

    constructor(data?: ServiceMigrationListResultModelAttributesInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceMigrationListResultModelAttributes, value: StringDateInterface<ServiceMigrationListResultModelAttributesInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceMigrationListResultModelAttributesFromJSON(json: any): ServiceMigrationListResultModelAttributes {
    return ServiceMigrationListResultModelAttributesFromJSONTyped(json, false);
}

export function ServiceMigrationListResultModelAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMigrationListResultModelAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceMigrationListResultModelAttributes;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModelAttributes');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function ServiceMigrationListResultModelAttributesToJSON(value?: ServiceMigrationListResultModelAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceMigrationListResultModelAttributes;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModelAttributes');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'name': value.name,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at === null ? null : value.deleted_at.toApiFormat()),
    } as StringDateInterface<ServiceMigrationListResultModelAttributes>;
    return createClass.toJson(value, model);
}


