import { ApiResponseModel, ServiceMigrationHistoryListRequest, ServiceMigrationVersionsRequest, ServiceMigrationHistoryListModel, ServiceVersionListResultModel, ServiceVersionModel } from "api";
import ServiceContainer from "api/override/ServiceContainerModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface ServiceMigrationParams {
    serviceContainer: ServiceContainer;
    version: string;
}

export interface ServiceMigrationCreate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_MIGRATION_CREATE;
    params: ServiceMigrationParams;
}

export interface ServiceMigrationCreateSuccess extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_MIGRATION_CREATE_SUCCESS;
    params: ApiResponseModel;
}

export interface ServiceMigrationHistory extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_MIGRATION_HISTORY;
    params: ServiceMigrationHistoryListRequest;
}

export interface ServiceMigrationHistorySuccess extends BaseSagaListResponse<ServiceMigrationHistoryListModel> {
    type: ActionTypes.API_SERVICE_MIGRATION_HISTORY_SUCCESS;
}

export interface ServiceMigrationVersionList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_MIGRATION_VERSION;
    params: ServiceMigrationVersionsRequest;
}

export interface ServiceMigrationVersionListSuccess extends BaseSagaListResponse<ServiceVersionModel> {
    type: ActionTypes.API_SERVICE_MIGRATION_VERSION_SUCCESS;
}

export default class ServiceMigrationActions {

    static CreateServiceMigrationCreate(serviceContainer: ServiceContainer, version: string): ServiceMigrationCreate {
        return {
            type: ActionTypes.API_SERVICE_MIGRATION_CREATE,
            params: {
                serviceContainer: serviceContainer,
                version: version
            }
        }
    }

    static CreateServiceMigrationCreateSuccess(params: ApiResponseModel): ServiceMigrationCreateSuccess {
        return {
            type: ActionTypes.API_SERVICE_MIGRATION_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceMigrationHistory(params: ServiceMigrationHistoryListRequest): ServiceMigrationHistory {
        return {
            type: ActionTypes.API_SERVICE_MIGRATION_HISTORY,
            params: params
        }
    }

    static CreateServiceMigrationHistorySuccess(params: ServiceMigrationHistoryListModel): ServiceMigrationHistorySuccess {
        return {
            type: ActionTypes.API_SERVICE_MIGRATION_HISTORY_SUCCESS,
            params: params
        }
    }

    static CreateServiceMigrationVersionList(params: ServiceMigrationVersionsRequest): ServiceMigrationVersionList {
        return {
            type: ActionTypes.API_SERVICE_MIGRATION_VERSION,
            params: params
        }
    }

    static CreateServiceMigrationVersionListSuccess(params: ServiceVersionListResultModel): ServiceMigrationVersionListSuccess {
        return {
            type: ActionTypes.API_SERVICE_MIGRATION_VERSION_SUCCESS,
            params: params
        }
    }

}