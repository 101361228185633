import { Fade, Theme } from "@mui/material";
import { BaseTabView, BaseTabManagerProps, RawBaseTabManager } from "components/base/BaseTabModal";
import React from "react";
import OldTabPanel from "./OldTabPanel";
import BaseModal from "components/base/BaseModal";
import { makeStyles } from 'tss-react/mui';


export interface TabView extends BaseTabView {
    view: JSX.Element;
    keepRendered?: boolean;
}

export interface TabManagerProps extends Omit<BaseTabManagerProps, 'appbarElevation' | 'renderContent'> {
    items: TabView[];
    open: boolean;
    onClose: () => void;
}

const useTabManagerStyles = makeStyles()((theme: Theme) => {
    return {
        bbTabManagerRoot: {
            display: 'grid',
            overflowY: 'auto',
            overflowX: 'hidden',
            gridTemplateRows: 'min-content auto',
            gridTemplateAreas: `
                'tabs'
                'tabpanel'
            `,
            gridTemplateColumns: '1fr',
            height: '100%',
            position: 'relative',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            minHeight: '60vh'
        },
        bbTabPanelRoot: {
            height: '100%',

            '&.hiddenRender': {
                display: 'none'
            }
        },
        bbTabItem: {
            paddingBottom: theme.spacing(2),
        }
    }
});

const TabManager = (props: TabManagerProps) => {
    const style = useTabManagerStyles();
    const a11yTabpanelProps = (index: any) => {
        return {
            id: `tabpanel-${index}`,
            'aria-labelledby': `tab-${index}`,
        }
    }
    return (
        <BaseModal open={props.open} onModalClosePress={props.onClose}> 
            <RawBaseTabManager
                {...props}
                renderContent={(activeTab: number, tabItems: BaseTabView[]) => {
                    const items = tabItems as TabView[];
                    return (
                        <> 
                            {items.map((tabItem: TabView, index: number) => (
                                <OldTabPanel className={style.classes.bbTabManagerRoot +`${tabItem.keepRendered === true ? activeTab === index ? '' : ' hiddenRender' : ''}`} key={`tabpanel-${tabItem.title}`} hidden={tabItem.keepRendered === true ? false : activeTab !== index} {...a11yTabpanelProps(index)}>
                                    <Fade in={activeTab === index}>
                                        <div style={{...props.style}} className={style.classes.bbTabItem} >
                                            {tabItem.view}
                                        </div>
                                    </Fade>
                                </OldTabPanel>
                            ))}
                        </>
                    )
                }}
            />
        </BaseModal>
    )
}


export const RawTabManager = TabManager;
export default React.memo(TabManager, (prevProps: TabManagerProps, nextProps: TabManagerProps) => {
    return nextProps.items.map(tv => tv.link).join('') === prevProps.items.map(tv => tv.link).join('')
});
