import React, { useState } from 'react';
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import Instance from 'api/override/InstanceModel';
import { retrieveServiceContainerSchema } from 'schemas/ServiceContainer';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import ImageVersionController from 'controllers/ImageVersionController';
import { FormikProps } from 'formik';
import ServiceSingleSelect from 'components/form_controls/ServiceSingleSelect';
import ServiceController from 'controllers/ServiceController';
import ServiceContainer from 'api/override/ServiceContainerModel';

interface ServiceContainerCreateDialogProps extends BaseFormModalProps<ServiceContainer> {
    instance?: Instance
}

const ServiceContainerCreateDialog = (props: ServiceContainerCreateDialogProps) => {

    const { t } = useTranslation()
    const { classes } = useDialogStyles()
    const [serviceController] = useState<ServiceController>(new ServiceController());
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const getChosenServiceImageId = (formik: FormikProps<ServiceContainer>): number | undefined => {
        if(formik.values.service_id != null) {
            return +serviceController.getCachedItemByValue(formik.values.service_id)?.['image_id']
        }
        return undefined
    }
    return (
        <SmallFormModal {...props}  
            title={t('global.action.create_model', {model: t('service_container.model')})}
            initialValues={new ServiceContainer({
                instance_uuid: props.instance?.uuid,
                service_id: 0,
                image_version_id: 0,
                image_version: null
            })}
            action={ActionTypes.API_SERVICE_CONTAINER_CREATE}
            validationSchema={retrieveServiceContainerSchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    
                    <ServiceSingleSelect
                        id="service_id"
                        name='service_id'
                        controller={serviceController}
                        formik={formik}
                    />

                    <ImageVersionSingleSelect
                        id='image_version'
                        name='image_version_id'
                        controller={imageVersionController}
                        formik={formik}
                        imageId={getChosenServiceImageId(formik)}
                        disabled={formik.values.service_id == null}
                        label={t('image_versions.model')}
                    />
                </div>
            }
        />
    )
}

export default ServiceContainerCreateDialog