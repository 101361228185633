/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ServiceMigrationListResultModelData,
    ServiceMigrationListResultModelDataFromJSON,
    ServiceMigrationListResultModelDataFromJSONTyped,
    ServiceMigrationListResultModelDataToJSON,
    ServiceMigrationListResultModelLinks,
    ServiceMigrationListResultModelLinksFromJSON,
    ServiceMigrationListResultModelLinksFromJSONTyped,
    ServiceMigrationListResultModelLinksToJSON,
    ServiceMigrationListResultModelMeta,
    ServiceMigrationListResultModelMetaFromJSON,
    ServiceMigrationListResultModelMetaFromJSONTyped,
    ServiceMigrationListResultModelMetaToJSON,
} from './';


export interface ServiceMigrationListResultModelInterface {
    data?: Array<ServiceMigrationListResultModelData>;
    meta?: ServiceMigrationListResultModelMeta;
    links?: ServiceMigrationListResultModelLinks;
}

/**
 * 
 * @export
 * @interface ServiceMigrationListResultModel
 */
export class ServiceMigrationListResultModel extends BaseModel<ServiceMigrationListResultModelInterface> {

    /**
     * 
     * @type {Array<ServiceMigrationListResultModelData>}
     * @memberof ServiceMigrationListResultModel
     */
    data?: Array<ServiceMigrationListResultModelData>;
    /**
     * 
     * @type {ServiceMigrationListResultModelMeta}
     * @memberof ServiceMigrationListResultModel
     */
    meta?: ServiceMigrationListResultModelMeta;
    /**
     * 
     * @type {ServiceMigrationListResultModelLinks}
     * @memberof ServiceMigrationListResultModel
     */
    links?: ServiceMigrationListResultModelLinks;

    constructor(data?: ServiceMigrationListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceMigrationListResultModel, value: StringDateInterface<ServiceMigrationListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceMigrationListResultModelFromJSON(json: any): ServiceMigrationListResultModel {
    return ServiceMigrationListResultModelFromJSONTyped(json, false);
}

export function ServiceMigrationListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMigrationListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceMigrationListResultModel;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ServiceMigrationListResultModelDataFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : ServiceMigrationListResultModelMetaFromJSON(json['meta']),
        'links': !exists(json, 'links') ? undefined : ServiceMigrationListResultModelLinksFromJSON(json['links']),
    });
}

export function ServiceMigrationListResultModelToJSON(value?: ServiceMigrationListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceMigrationListResultModel;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ServiceMigrationListResultModelDataToJSON)),
        'meta': ServiceMigrationListResultModelMetaToJSON(value.meta),
        'links': ServiceMigrationListResultModelLinksToJSON(value.links),
    } as StringDateInterface<ServiceMigrationListResultModel>;
    return createClass.toJson(value, model);
}


