/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabasePatchSuccessModelInterface {
    message?: string;
    process_token?: string;
}

/**
 * 
 * @export
 * @interface MetabasePatchSuccessModel
 */
export class MetabasePatchSuccessModel extends BaseModel<MetabasePatchSuccessModelInterface> {

    /**
     * A message stating the success or error
     * @type {string}
     * @memberof MetabasePatchSuccessModel
     */
    message?: string;
    /**
     * Remove Process Token
     * @type {string}
     * @memberof MetabasePatchSuccessModel
     */
    process_token?: string;

    constructor(data?: MetabasePatchSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabasePatchSuccessModel, value: StringDateInterface<MetabasePatchSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabasePatchSuccessModelFromJSON(json: any): MetabasePatchSuccessModel {
    return MetabasePatchSuccessModelFromJSONTyped(json, false);
}

export function MetabasePatchSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabasePatchSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabasePatchSuccessModel;
    try {
        let localClass = require.context('../override', true)('./MetabasePatchSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'process_token': !exists(json, 'process_token') ? undefined : json['process_token'],
    });
}

export function MetabasePatchSuccessModelToJSON(value?: MetabasePatchSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabasePatchSuccessModel;
    try {
        let localClass = require.context('../override', true)('./MetabasePatchSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'process_token': value.process_token,
    } as StringDateInterface<MetabasePatchSuccessModel>;
    return createClass.toJson(value, model);
}


