import React from 'react';
import ServiceContainer from "api/override/ServiceContainerModel";
import { Formik, FormikProps } from 'formik';
import MultiSelectOption from 'interface/MultiSelectOption';
import SCVersionSelect from 'components/form_controls/SCVersionSelect'; 
import BaseDialogActions from 'components/base/BaseDialogActions';
import { useDispatch } from 'react-redux';
import ServiceMigrationActions from 'reducers/types/ServiceMigration';

interface ServiceContainerVersionSelectViewProps {
    serviceContainer: ServiceContainer;
}

interface VersionSelect {
    version?: MultiSelectOption;
}

const ServiceContainerVersionSelectView = (props: ServiceContainerVersionSelectViewProps) => {
    const dispatch = useDispatch();
    const onSubmit = (form: VersionSelect) => {
        dispatch(
            ServiceMigrationActions.CreateServiceMigrationCreate(
                props.serviceContainer,
                `${form.version.value}`
            )
        )
    }

    return (
        <Formik
            initialValues={{
                version: null
            } as VersionSelect}
            onSubmit={onSubmit}
        >
            {(formikProps: FormikProps<VersionSelect>) => (
                <>
                    <SCVersionSelect
                        formik={formikProps}
                        serviceContainer={props.serviceContainer}
                    />
                    <BaseDialogActions hasSave onModalClosePress={() => {}} onConfirm={formikProps.submitForm} />
                </>
            )}
        </Formik>
    )
}

export default ServiceContainerVersionSelectView;