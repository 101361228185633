/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
} from './';


export interface ServiceMigrationCreateSuccessModelInterface {
}

/**
 * 
 * @export
 * @interface ServiceMigrationCreateSuccessModel
 */
export class ServiceMigrationCreateSuccessModel extends BaseModel<ServiceMigrationCreateSuccessModelInterface> {


    constructor(data?: ServiceMigrationCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceMigrationCreateSuccessModel, value: StringDateInterface<ServiceMigrationCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceMigrationCreateSuccessModelFromJSON(json: any): ServiceMigrationCreateSuccessModel {
    return ServiceMigrationCreateSuccessModelFromJSONTyped(json, false);
}

export function ServiceMigrationCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMigrationCreateSuccessModel {
    return json;
}

export function ServiceMigrationCreateSuccessModelToJSON(value?: ServiceMigrationCreateSuccessModel | null): any {
    return value;
}


