/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ServiceMigrationListResultModelAttributes,
    ServiceMigrationListResultModelAttributesFromJSON,
    ServiceMigrationListResultModelAttributesFromJSONTyped,
    ServiceMigrationListResultModelAttributesToJSON,
} from './';


export interface ServiceMigrationListResultModelDataInterface {
    type?: string;
    id?: string;
    attributes?: ServiceMigrationListResultModelAttributes;
}

/**
 * 
 * @export
 * @interface ServiceMigrationListResultModelData
 */
export class ServiceMigrationListResultModelData extends BaseModel<ServiceMigrationListResultModelDataInterface> {

    /**
     * 
     * @type {string}
     * @memberof ServiceMigrationListResultModelData
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceMigrationListResultModelData
     */
    id?: string;
    /**
     * 
     * @type {ServiceMigrationListResultModelAttributes}
     * @memberof ServiceMigrationListResultModelData
     */
    attributes?: ServiceMigrationListResultModelAttributes;

    constructor(data?: ServiceMigrationListResultModelDataInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceMigrationListResultModelData, value: StringDateInterface<ServiceMigrationListResultModelDataInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceMigrationListResultModelDataFromJSON(json: any): ServiceMigrationListResultModelData {
    return ServiceMigrationListResultModelDataFromJSONTyped(json, false);
}

export function ServiceMigrationListResultModelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMigrationListResultModelData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceMigrationListResultModelData;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModelData');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'attributes': !exists(json, 'attributes') ? undefined : ServiceMigrationListResultModelAttributesFromJSON(json['attributes']),
    });
}

export function ServiceMigrationListResultModelDataToJSON(value?: ServiceMigrationListResultModelData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceMigrationListResultModelData;
    try {
        let localClass = require.context('../override', true)('./ServiceMigrationListResultModelData');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'type': value.type,
        'id': value.id,
        'attributes': ServiceMigrationListResultModelAttributesToJSON(value.attributes),
    } as StringDateInterface<ServiceMigrationListResultModelData>;
    return createClass.toJson(value, model);
}


