import { ServiceMigrationCreateRequest, ServiceMigrationApi, ServiceVersionListResultModel, ServiceMigrationVersionsRequest } from "api";
import ActionTypes from "config/ActionTypes";
import { AppActions } from "reducers/types/App";
import ServiceMigrationActions, { ServiceMigrationCreate, ServiceMigrationHistory, ServiceMigrationVersionList } from "reducers/types/ServiceMigration";
import { takeLatest, put } from "redux-saga/effects";
import { ApiHandler } from "./APIHandler";

export function* ServiceMigrationSagas() {
    yield takeLatest(ActionTypes.API_SERVICE_MIGRATION_HISTORY, ServiceMigrationSaga.serviceMigrationHistorySaga);
    yield takeLatest(ActionTypes.API_SERVICE_MIGRATION_CREATE, ServiceMigrationSaga.serviceMigrationCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_MIGRATION_VERSION, ServiceMigrationSaga.serviceMigrationVersionListWorkerSaga);
}

export class ServiceMigrationSaga {

    static *serviceMigrationHistorySaga(action: ServiceMigrationHistory) {
        try {
            const data = yield ApiHandler(
                new ServiceMigrationApi().serviceMigrationHistoryList,
                action.params
            )
            yield put(ServiceMigrationActions.CreateServiceMigrationHistorySuccess(data))
        } catch (error) {
            yield put(
                AppActions.CreateError(
                    ActionTypes.API_SERVICE_MIGRATION_HISTORY_FAILURE,
                    error as Error
                )
            )
        }
    }

    static *serviceMigrationCreateWorkerSaga(action: ServiceMigrationCreate) {
        try {
            const ccArgs = {
                uuid: action.params.serviceContainer.uuid,
                serviceMigrationCreateModel: {
                    version: action.params.version
                },
            } as ServiceMigrationCreateRequest
            const data = yield ApiHandler(
                new ServiceMigrationApi().serviceMigrationCreate,
                ccArgs
            )
            yield put(ServiceMigrationActions.CreateServiceMigrationCreateSuccess(data))
        } catch (error) {
            yield put(
                AppActions.CreateError(
                    ActionTypes.API_SERVICE_MIGRATION_CREATE_FAILURE,
                    error as Error
                )
            )
        }
    }


    static *serviceMigrationVersionListWorkerSaga(action: ServiceMigrationVersionList) {
        try {
            const data = yield ApiHandler(ServiceMigrationSaga.serviceMigrationVersionList, action.params);
            yield put(ServiceMigrationActions.CreateServiceMigrationVersionListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_MIGRATION_VERSION_FAILURE, error as Error))
        }
    }

    static async serviceMigrationVersionList (params: ServiceMigrationVersionsRequest): Promise<ServiceVersionListResultModel> {
        return await new ServiceMigrationApi().serviceMigrationVersions(params);
    }

}