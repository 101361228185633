import React from 'react';

interface TabPanelProps {
    className: any;
    children?: React.ReactNode;
    hidden: boolean;
};

const OldTabPanel = (props: TabPanelProps) => {
    const { children, hidden, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={props.hidden}
            style={{'display': !props.hidden ? 'block' : 'none'}}
            {...other}
        >
            {!props.hidden && (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
};

export default OldTabPanel;