import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { retrieveServiceContainerSchema } from 'schemas/ServiceContainer';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import ServiceSingleSelect from 'components/form_controls/ServiceSingleSelect';
import ServiceController from 'controllers/ServiceController';
import Service from 'api/override/ServiceModel';
import { BaseFormModalProps } from 'components/base/BaseFormModal';

interface FormProps {
    service_id?: number;
}

interface ServiceSelectDialogProps extends BaseFormModalProps<FormProps> {
    open: boolean;
    onConfirm: (service: Service) => void;
}

const ServiceSelectDialog = (props: ServiceSelectDialogProps) => {

    const { t } = useTranslation()
    const { classes } = useDialogStyles()
    const [serviceController] = useState<ServiceController>(new ServiceController());
    const onSubmit = (values: FormProps) => {
        props.onConfirm(serviceController.getCachedItemByValue(values.service_id) as Service)
    }
    return (
        <SmallFormModal 
            {...props}  
            title={t('service.redeploy-all-containers')}
            initialValues={{
                service_id: null
            } as FormProps}
            action={ActionTypes.API_SERVICE_CONTAINER_CREATE}
            validationSchema={retrieveServiceContainerSchema()}
            onSubmit={onSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <ServiceSingleSelect 
                        id="service_id"
                        name='service_id'
                        controller={serviceController}
                        formik={formik}
                    />
                </div>
            }
        />
    )
}

export default ServiceSelectDialog