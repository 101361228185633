/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabaseModelInterface {
    id?: number;
    name?: string;
}

/**
 * 
 * @export
 * @interface MetabaseModel
 */
export class MetabaseModel extends BaseModel<MetabaseModelInterface> {

    /**
     * id of the metabase account
     * @type {number}
     * @memberof MetabaseModel
     */
    id?: number;
    /**
     * the name of the metabase account
     * @type {string}
     * @memberof MetabaseModel
     */
    name?: string;

    constructor(data?: MetabaseModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseModel, value: StringDateInterface<MetabaseModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseModelFromJSON(json: any): MetabaseModel {
    return MetabaseModelFromJSONTyped(json, false);
}

export function MetabaseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    });
}

export function MetabaseModelToJSON(value?: MetabaseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
    } as StringDateInterface<MetabaseModel>;
    return createClass.toJson(value, model);
}


