enum Tasks {
    CREATE = 'create',
    DELETE = 'delete',
    BACKUP = 'backup',
    REDEPLOY = 'redeploy',
    BACKEND_REDEPLOY = 'backend-redeploy',
    FRONTEND_REDEPLOY = 'frontend-redeploy',
    BACKEND_MULTI_REDEPLOY = 'backend-multi-redeploy',
    FRONTEND_MULTI_REDEPLOY = 'frontend-multi-redeploy',
    MULTI_REDEPLOY = 'multi-redeploy',
    MIGRATION = 'migration',
    CLEANUP = 'cleanup',
    PATCH = 'patch',
    UPGRADE = 'upgrade',
    UPDATE = 'update',
    MIGRATE = 'migrate',
}

export default Tasks