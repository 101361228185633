/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabaseApiKeyModelInterface {
    key?: string;
}

/**
 * 
 * @export
 * @interface MetabaseApiKeyModel
 */
export class MetabaseApiKeyModel extends BaseModel<MetabaseApiKeyModelInterface> {

    /**
     * The API key of the instance
     * @type {string}
     * @memberof MetabaseApiKeyModel
     */
    key?: string;

    constructor(data?: MetabaseApiKeyModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseApiKeyModel, value: StringDateInterface<MetabaseApiKeyModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseApiKeyModelFromJSON(json: any): MetabaseApiKeyModel {
    return MetabaseApiKeyModelFromJSONTyped(json, false);
}

export function MetabaseApiKeyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseApiKeyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseApiKeyModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseApiKeyModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'key': !exists(json, 'key') ? undefined : json['key'],
    });
}

export function MetabaseApiKeyModelToJSON(value?: MetabaseApiKeyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseApiKeyModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseApiKeyModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'key': value.key,
    } as StringDateInterface<MetabaseApiKeyModel>;
    return createClass.toJson(value, model);
}


