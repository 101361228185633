/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabaseToggleSuccessModelInterface {
    message?: string;
}

/**
 * 
 * @export
 * @interface MetabaseToggleSuccessModel
 */
export class MetabaseToggleSuccessModel extends BaseModel<MetabaseToggleSuccessModelInterface> {

    /**
     * The success message for the toggle action
     * @type {string}
     * @memberof MetabaseToggleSuccessModel
     */
    message?: string;

    constructor(data?: MetabaseToggleSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseToggleSuccessModel, value: StringDateInterface<MetabaseToggleSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseToggleSuccessModelFromJSON(json: any): MetabaseToggleSuccessModel {
    return MetabaseToggleSuccessModelFromJSONTyped(json, false);
}

export function MetabaseToggleSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseToggleSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseToggleSuccessModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseToggleSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function MetabaseToggleSuccessModelToJSON(value?: MetabaseToggleSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseToggleSuccessModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseToggleSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
    } as StringDateInterface<MetabaseToggleSuccessModel>;
    return createClass.toJson(value, model);
}


